<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-customer-user-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ actionTitle }}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeFormButton"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="col col-6">
                            <label class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="customerUser.active"
                                    id="customerUserActiveInput"
                                />
                                <span class="form-check-label">Ativo</span>
                            </label>
                        </div>
                        <div class="col col-6 mb-3">
                            <label class="form-label">Cliente</label>
                            <select
                                class="form-select"
                                v-model="customerUser.customer"
                                id="customerUserCustomerSelect"
                            >
                                <option
                                    v-for="customer in customersList"
                                    v-bind:key="customer._id"
                                    v-bind:value="customer._id"
                                >
                                    {{ customer.name }}
                                </option>
                            </select>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Nome</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customerUser.firstname"
                                    placeholder="Nome do usuário"
                                    id="customerUserFirstnameInput"
                                />
                            </div>
                            <div class="col">
                                <label class="form-label">Sobrenome</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customerUser.lastname"
                                    placeholder="Sobrenome do usuário"
                                    id="customerUserLastnameInput"
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">E-mail</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="customerUser.email"
                                placeholder="E-mail do usuário"
                                id="customerUserEmailInput"
                            />
                        </div>
                        <div class="row mb-3">
                            <div class="col col-6">
                                <label class="form-label">Administrador?</label>
                                <select
                                    class="form-select"
                                    v-model="customerUser.is_admin"
                                    id="customerUserIsAdminSelector"
                                >
                                    <option v-bind:value="false">Não</option>
                                    <option v-bind:value="true">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div class="col col-6" v-if="action == 'edit'">
                            <label class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="change_password"
                                    id="customerUserChangePasswordInput"
                                />
                                <span class="form-check-label"
                                    >Alterar senha do usuário</span
                                >
                            </label>
                        </div>
                        <div
                            class="row mb-3"
                            v-if="change_password || action == 'create'"
                        >
                            <div class="col">
                                <label class="form-label"
                                    >Senha de acesso</label
                                >
                                <input
                                    type="password"
                                    class="form-control"
                                    v-model="customerUser.password"
                                    id="customerUserPasswordInput"
                                />
                            </div>
                            <div class="col">
                                <label class="form-label"
                                    >Confirme a senha</label
                                >
                                <input
                                    type="password"
                                    class="form-control"
                                    v-model="customerUser.confirm_password"
                                    id="customerUserConfirmPasswordInput"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                            id="customerUserCancelButton"
                        >
                            Cancelar
                        </button>
                        <button
                            class="btn btn-primary ms-auto"
                            data-bs-dismiss="modal"
                            @click="save()"
                            id="customerUserSaveButton"
                        >
                            <i class="ti ti-plus"></i>
                            Salvar usuário
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"

export default {
    name: "CustomerUserForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "create"
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Criar usuário",
            customerUser: {},
            customersList: [],
            change_password: false
        }
    },
    async mounted() {
        if (this.action === "edit") {
            this.actionTitle = "Editar usuário"
        }

        var customersListResponse = await Api.customers.all()
        this.customersList = customersListResponse.data

        this.customerUser = structuredClone(this.data)
        if (this.action == "edit") {
            this.customerUser.customer = this.customerUser.customer._id
        }
        var modal = new bootstrap.Modal(
            document.getElementById("modal-customer-user-form")
        )
        modal.show()
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            if (this.action == "edit" && !this.change_password) {
                this.customerUser.password = null
                this.customerUser.confirm_password = null
            }
            this.$emit("save", this.customerUser)
        }
    }
}
</script>
