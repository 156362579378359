<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-user-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ actionTitle }}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeFormButton"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="col col-6">
                            <label class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="user.active"
                                    id="userActiveCheckbox"
                                />
                                <span class="form-check-label">Ativo</span>
                            </label>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Nome</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="user.firstname"
                                    placeholder="Nome do usuário"
                                    id="userFirstnameInput"
                                />
                            </div>
                            <div class="col">
                                <label class="form-label">Sobrenome</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="user.lastname"
                                    placeholder="Sobrenome do usuário"
                                    id="userLastnameInput"
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">E-mail</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="user.email"
                                placeholder="E-mail do usuário"
                                id="userEmailInput"
                            />
                        </div>
                        <div class="row mb-3">
                            <div class="col col-6">
                                <label class="form-label">Administrador?</label>
                                <select
                                    class="form-select"
                                    v-model="user.is_admin"
                                    id="userIsAdminSelect"
                                >
                                    <option v-bind:value="false">Não</option>
                                    <option v-bind:value="true">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div class="col col-6" v-if="action == 'edit'">
                            <label class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="change_password"
                                    id="changePasswordCheckbox"
                                />
                                <span class="form-check-label"
                                    >Alterar senha do usuário</span
                                >
                            </label>
                        </div>
                        <div
                            class="row mb-3"
                            v-if="change_password || action == 'create'"
                        >
                            <div class="col">
                                <label class="form-label"
                                    >Senha de acesso</label
                                >
                                <input
                                    type="password"
                                    class="form-control"
                                    v-model="user.password"
                                    id="userPasswordInput"
                                />
                            </div>
                            <div class="col">
                                <label class="form-label"
                                    >Confirme a senha</label
                                >
                                <input
                                    type="password"
                                    class="form-control"
                                    v-model="user.confirm_password"
                                    id="userConfirmPasswordInput"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                            id="userCancelButton"
                        >
                            Cancelar
                        </button>
                        <button
                            class="btn btn-primary ms-auto"
                            data-bs-dismiss="modal"
                            @click="save()"
                            id="userSaveButton"
                        >
                            <i class="ti ti-plus"></i>
                            Salvar usuário
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "create"
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Criar usuário",
            user: {},
            workspacesList: [],
            change_password: false
        }
    },
    async mounted() {
        if (this.action === "edit") {
            this.actionTitle = "Editar usuário"
        }

        this.$watch("data", (newData) => {
            this.user = newData
        })
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            if (this.action == "edit" && !this.change_password) {
                this.user.password = null
                this.user.confirm_password = null
            }
            this.$emit("save", this.user)
        }
    }
}
</script>
