<template>
    <div>
        <div class="card" v-if="flowTemplateList.length">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-template"></i>
                        Galeria de templates
                    </h3>
                </div>
                <div class="col-auto">
                    <button
                        @click="newFlowTemplate()"
                        data-bs-target="#modal-flow-template-form"
                        data-bs-toggle="modal"
                        class="btn btn-primary"
                        v-if="user.is_admin"
                        id="newFlowTemplateButton"
                    >
                        <i class="ti ti-plus"></i>
                        Novo template
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Thumbnail</th>
                            <th>Nome do template</th>
                            <th>Tags</th>
                            <th>Atualização</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="flowTemplate in flowTemplateList"
                            v-bind:key="flowTemplate._id"
                        >
                            <td width="130">
                                <div
                                    class="thumbnail-mask"
                                    v-if="flowTemplate.imageData"
                                >
                                    <img v-bind:src="flowTemplate.imageData" />
                                </div>
                                <div
                                    class="thumbnail-no-image"
                                    v-if="!flowTemplate.imageData"
                                >
                                    Sem imagem
                                </div>
                            </td>
                            <td>{{ flowTemplate.name }}</td>
                            <td>
                                <span
                                    v-if="flowTemplate.enabled"
                                    class="badge bg-success-lt"
                                    >Publicado</span
                                >
                                <span v-else class="badge bg-danger-lt"
                                    >Draft</span
                                >
                            </td>
                            <td>{{ formatDate(flowTemplate.updated_at) }}</td>
                            <td>
                                <button
                                    @click="editFlowTemplate(flowTemplate)"
                                    class="btn btn-sm editFlowTemplateButton"
                                    data-bs-target="#modal-flow-template-form"
                                    data-bs-toggle="modal"
                                    v-if="user.is_admin"
                                >
                                    <i class="ti ti-pencil"></i>
                                    Editar template
                                </button>
                                <span>&nbsp;</span>
                                <a
                                    class="btn btn-sm removeFlowTemplateButton"
                                    href="#"
                                    v-if="user.is_admin"
                                    @click="removeTemplate(flowTemplate._id)"
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover template
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                Total de
                <strong>{{ flowTemplateList.length }} templates</strong>
                <br />
            </div>
        </div>

        <div class="card" v-if="!flowTemplateList.length">
            <div class="card-header">
                <h3 class="card-title">
                    <i class="ti ti-template"></i>
                    Galeria de templates
                </h3>
            </div>
            <div class="card-body">
                <div class="empty">
                    <p class="empty-title">Nenhum template ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar um novo template
                    </p>
                    <div class="empty-action">
                        <button
                            @click="newFlowTemplate()"
                            data-bs-target="#modal-flow-template-form"
                            data-bs-toggle="modal"
                            class="btn btn-primary"
                            v-if="user.is_admin"
                            id="newFlowTemplateButton"
                        >
                            <i class="ti ti-plus"></i>
                            Criar template
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <FlowTemplateForm
            :action="flowTemplateFormAction"
            :data="flowTemplateFormData"
            @save="saveFlowTemplate"
        />
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"
import FlowTemplateForm from "../components/FlowTemplateForm"
import { store } from "../store"

export default {
    name: "FlowTemplatesPage",
    components: {
        FlowTemplateForm
    },
    data() {
        return {
            flowTemplateFormAction: "create",
            flowTemplateFormData: null,
            flowTemplateList: [],
            user: {}
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true

        this.user = store.user
        await this.loadFlowTemplates()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadFlowTemplates() {
            await Api.flowTemplates.all().then((response) => {
                this.flowTemplateList = response.data
            })
        },
        newFlowTemplate() {
            this.flowTemplateFormAction = "create"
            this.flowTemplateFormData = {}
        },
        editFlowTemplate(flowTemplate) {
            this.flowTemplateFormAction = "edit"
            this.flowTemplateFormData = flowTemplate
        },
        async removeTemplate(id) {
            if (confirm("Tem certeza que deseja remover este template?")) {
                await Api.flowTemplates.delete(id)
                await this.loadFlowTemplates()
            }
        },
        async saveFlowTemplate(data) {
            try {
                if (this.flowTemplateFormAction == "create") {
                    data.workspace = this.workspace_id
                    await Api.flowTemplates.create(data)
                } else {
                    await Api.flowTemplates.update(data._id, data)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Template salvo com sucesso"
                })

                await this.loadFlowTemplates()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o template"
                })
            }
        }
    }
}
</script>
