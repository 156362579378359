import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import App from "./App.vue"
import EventBus from "./services/event-bus"

// Vcalendar
import VCalendar from "v-calendar"
import "v-calendar/dist/style.css"

// Tabler UI
import "@tabler/core/dist/css/tabler.css"
import "@tabler/core/dist/js/tabler"
import "./assets/styles.css"

// Maska (input mask)
import Maska from "maska"

// pages
// import HomePage from './pages/HomePage.vue';
import LoginPage from "./pages/LoginPage.vue"
import UserProfilePage from "./pages/UserProfile.vue"
import UsersPage from "./pages/UsersPage.vue"
import CustomersPage from "./pages/CustomersPage.vue"
import CustomerDeletePage from "./pages/CustomerDeletePage.vue"
import WorkspacesPage from "./pages/WorkspacesPage.vue"
import WorkspaceDeletePage from "./pages/WorkspaceDeletePage.vue"
import CustomerUsersPage from "./pages/CustomerUsersPage.vue"
import CustomerUserDeletePage from "./pages/CustomerUserDeletePage.vue"
import CustomerCompanyPage from "./pages/CustomerCompanyPage.vue"
import FlowTemplatesPage from "./pages/FlowTemplatesPage.vue"

import { store } from "./store"

const routes = [
    { path: "/", redirect: "/customers", beforeEnter: checkLogin },
    { path: "/login", component: LoginPage },
    {
        path: "/user/profile",
        component: UserProfilePage,
        beforeEnter: checkLogin
    },
    { path: "/customers", component: CustomersPage, beforeEnter: checkLogin },
    {
        path: "/customers/delete/:id",
        component: CustomerDeletePage,
        beforeEnter: checkLogin
    },
    {
        path: "/customers/workspaces",
        component: WorkspacesPage,
        beforeEnter: checkLogin
    },
    {
        path: "/customers/workspaces/delete/:id",
        component: WorkspaceDeletePage,
        beforeEnter: checkLogin
    },
    {
        path: "/customers/users",
        component: CustomerUsersPage,
        beforeEnter: checkLogin
    },
    {
        path: "/customers/users/delete/:id",
        component: CustomerUserDeletePage,
        beforeEnter: checkLogin
    },
    {
        path: "/customers/company/:id",
        component: CustomerCompanyPage,
        beforeEnter: checkLogin
    },
    { path: "/users", component: UsersPage, beforeEnter: checkLogin },
    {
        path: "/flow-templates",
        component: FlowTemplatesPage,
        beforeEnter: checkLogin
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

function checkLogin(to, from, next) {
    if (!localStorage.getItem("token")) {
        router.push("/login")
    }

    if (
        localStorage.getItem("token") &&
        localStorage.getItem("tokenExpiration")
    ) {
        let tokenExpiration = localStorage.getItem("tokenExpiration")
        if (tokenExpiration <= Date.now()) {
            router.push("/login")
        }
    }

    return next()
}

// global events
EventBus.on("call-logout", () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("tokenExpiration")
    router.push("/login")
})

EventBus.on("updated-user", (userData) => {
    store.user = userData
    localStorage.setItem("user", JSON.stringify(userData))
})

EventBus.on("clear-flow-editor-elements", () => {
    document.body.classList.remove("flow-editor")
    document.querySelectorAll(".leader-line").forEach((el) => el.remove())
    document.querySelectorAll("#leader-line-defs").forEach((el) => el.remove())
})

// initialize store data
store.user = JSON.parse(localStorage.getItem("user"))

// clear messages on router change
router.beforeEach((to, from, next) => {
    EventBus.emit("clear-flow-editor-elements")
    store.showBackButton = false
    next()
})

// apply body class when ajax request is running
EventBus.on("ajax-request-start", () => {
    document.body.classList.add("ajax-request")
})

EventBus.on("ajax-request-end", () => {
    setTimeout(() => {
        document.body.classList.remove("ajax-request")
    }, 300)
})

var app = createApp(App)

app.use(VCalendar, {})
app.use(Maska)
app.use(router)
app.mount("#app")
