<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-workspace-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ actionTitle }}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeFormButton"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col col-6">
                                <label class="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="workspace.enabled"
                                        id="workspaceEnabledInput"
                                    />
                                    <span class="form-check-label"
                                        >Habilitado</span
                                    >
                                </label>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col col-6">
                                <label class="form-label">Cliente</label>
                                <select
                                    class="form-select"
                                    v-model="workspace.customer"
                                    id="workspaceCustomerSelect"
                                >
                                    <option
                                        v-for="customer in customersList"
                                        v-bind:key="customer._id"
                                        v-bind:value="customer._id"
                                    >
                                        {{ customer.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Nome</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="workspace.name"
                                    placeholder="Nome do workspace"
                                    id="workspaceNameInput"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Descrição</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="workspace.description"
                                    placeholder="Descrição adicional (visível ao cliente)"
                                    id="workspaceDescriptionInput"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col">
                                <h4>Dados da engine do workspace</h4>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label">Frontend URL</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="workspace.engine_frontend_url"
                                    placeholder=""
                                    id="workspaceEngineFrontendUrlInput"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label">Backend URL</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="workspace.engine_backend_url"
                                    placeholder=""
                                    id="workspaceEngineBackendUrlInput"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label">Engine URL</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="workspace.engine_url"
                                    placeholder=""
                                    id="workspaceEngineUrlInput"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label"
                                    >Token da engine</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="workspace.engine_token"
                                    placeholder=""
                                    id="workspaceEngineTokenInput"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                            id="workspaceCancelButton"
                        >
                            Cancelar
                        </button>
                        <button
                            class="btn btn-primary ms-auto"
                            data-bs-dismiss="modal"
                            @click="save()"
                            id="workspaceSaveButton"
                        >
                            <i class="ti ti-plus"></i>
                            Salvar workspace
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"

export default {
    name: "WorkspaceForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "create"
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Criar workspace",
            workspace: {
                enabled: false,
                name: ""
            },
            customersList: []
        }
    },
    async mounted() {
        if (this.action === "edit") {
            this.actionTitle = "Editar workspace"
        }

        var customersListResponse = await Api.customers.all()
        this.customersList = customersListResponse.data

        this.$watch("data", (newData) => {
            if (this.action == "edit") {
                this.workspace = structuredClone(newData)
                this.workspace.customer = this.workspace.customer._id
            } else {
                this.workspace = {
                    enabled: false,
                    name: ""
                }
            }
        })
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            this.$emit("save", this.workspace)
        }
    }
}
</script>
