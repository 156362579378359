<template>
    <div>
        <div class="card" v-if="customersList.length">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-customers"></i>
                        Clientes
                    </h3>
                </div>
                <div class="col-auto">
                    <button
                        @click="newCustomer()"
                        data-bs-target="#modal-customer-form"
                        data-bs-toggle="modal"
                        class="btn btn-primary"
                        v-if="user.is_admin"
                        id="newCustomerAccountButton"
                    >
                        <i class="ti ti-plus"></i>
                        Criar conta de cliente
                    </button>
                </div>
            </div>
            <div>
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Status</th>
                            <th>Limites da conta</th>
                            <th>Atualização</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="customer in customersList"
                            v-bind:key="customer._id"
                        >
                            <td>{{ customer.name }}</td>
                            <td>
                                <span
                                    v-if="customer.active"
                                    class="badge bg-success-lt"
                                    >Ativo</span
                                >
                                <span v-else class="badge bg-danger-lt"
                                    >Inativo</span
                                >
                            </td>
                            <td>
                                <span class="dropdown">
                                    <button
                                        class="btn dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                    >
                                        Execuções:
                                        {{
                                            customer.config.flows_executions /
                                            1000
                                        }}k
                                    </button>
                                    <div class="dropdown-menu">
                                        <ul class="m-2">
                                            <li>
                                                Usuários:
                                                {{ customer.config.user_limit }}
                                            </li>
                                            <li>
                                                Workspaces:
                                                {{
                                                    customer.config
                                                        .workspace_limit
                                                }}
                                            </li>
                                            <li>
                                                Flows:
                                                {{ customer.config.flow_limit }}
                                            </li>
                                            <li>
                                                Nodes:
                                                {{ customer.config.node_limit }}
                                            </li>
                                            <li>
                                                Bandwidth:
                                                {{
                                                    customer.config
                                                        .bandwidth_limit
                                                }}Gb
                                            </li>
                                            <li>
                                                Execuções:
                                                {{
                                                    customer.config
                                                        .flows_executions /
                                                    1000
                                                }}k
                                            </li>
                                            <li>
                                                Storage:
                                                {{
                                                    customer.config
                                                        .storage_limit
                                                }}Gb
                                            </li>
                                            <li>
                                                Database:
                                                {{
                                                    customer.config
                                                        .database_limit
                                                }}Gb
                                            </li>
                                        </ul>
                                    </div>
                                </span>
                            </td>
                            <td>{{ formatDate(customer.updated_at) }}</td>
                            <td>
                                <button
                                    @click="editCustomer(customer)"
                                    class="btn btn-sm editAccountButton"
                                    data-bs-target="#modal-customer-form"
                                    data-bs-toggle="modal"
                                    v-if="user.is_admin"
                                >
                                    <i class="ti ti-pencil"></i>
                                    Editar conta
                                </button>
                                <span>&nbsp;</span>
                                <a
                                    v-bind:href="
                                        '/customers/company/' + customer._id
                                    "
                                    class="btn btn-sm editCompanyDataButton"
                                >
                                    <i class="ti ti-building"></i>
                                    Dados da empresa
                                </a>
                                <span>&nbsp;</span>
                                <a
                                    class="btn btn-sm removeAccountButton"
                                    v-bind:href="
                                        '/customers/delete/' + customer._id
                                    "
                                    v-if="user.is_admin"
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                Total de
                <strong>{{ customersList.length }} contas</strong>
                <br />
            </div>
        </div>

        <div class="card" v-if="!customersList.length">
            <div class="card-header">
                <h3 class="card-title">Contas de cliente</h3>
            </div>
            <div class="card-body">
                <div class="empty">
                    <p class="empty-title">Nenhuma conta criada ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar uma nova conta
                    </p>
                    <div class="empty-action">
                        <button
                            @click="newCustomer()"
                            data-bs-target="#modal-customer-form"
                            data-bs-toggle="modal"
                            class="btn btn-primary"
                            v-if="user.is_admin"
                            id="newCustomerAccountButton"
                        >
                            <i class="ti ti-plus"></i>
                            Criar conta de cliente
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <CustomerForm
            :action="customerFormAction"
            :data="customerFormData"
            @save="saveCustomer"
        />
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"
import CustomerForm from "../components/CustomerForm"
import { store } from "../store"

export default {
    name: "CustomersPage",
    components: {
        CustomerForm
    },
    data() {
        return {
            customerFormAction: "create",
            customerFormData: null,
            customersList: [],
            user: {}
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true

        this.user = store.user
        await this.loadCustomers()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadCustomers() {
            await Api.customers.all().then((response) => {
                this.customersList = response.data
            })
        },
        newCustomer() {
            this.customerFormAction = "create"
            this.customerFormData = {}
        },
        editCustomer(customer) {
            this.customerFormAction = "edit"
            this.customerFormData = customer
        },
        async saveCustomer(data) {
            try {
                if (this.customerFormAction == "create") {
                    data.workspace = this.workspace_id
                    await Api.customers.create(data)
                } else {
                    await Api.customers.update(data._id, data)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Cliente salvo com sucesso"
                })

                await this.loadCustomers()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o cliente"
                })
            }
        }
    }
}
</script>
