<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-customer-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ actionTitle }}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeFormButton"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="col">
                            <label class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="customer.active"
                                    id="customerActiveInput"
                                />
                                <span class="form-check-label">Ativo</span>
                            </label>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Nome</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.name"
                                    placeholder="Nome do cliente"
                                    id="customerNameInput"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de workspaces</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.workspace_limit"
                                    placeholder="Limite de workspaces"
                                    id="customerWorkspaceLimitInput"
                                />
                            </div>
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de usuários</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.user_limit"
                                    placeholder="Limite de usuários"
                                    id="customerUserLimitInput"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de flows</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.flow_limit"
                                    placeholder="Limite de flows"
                                    id="customerFlowLimitInput"
                                />
                            </div>
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de nodes por Flow</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.node_limit"
                                    placeholder="Limite de nodes"
                                    id="customerNodeLimitInput"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de execuções de flows</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.flows_executions"
                                    placeholder="Limite de execuções de flows"
                                    id="customerFlowsExecutionsInput"
                                />
                            </div>
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de storage</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.storage_limit"
                                    placeholder="Limite de execuções de flows"
                                    id="customerStorageLimitInput"
                                />
                                <small class="text-mutted">Em gigabytes</small>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite Database</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.database_limit"
                                    placeholder="Limite tamanho para Database"
                                    id="customerDatabaseLimitInput"
                                />
                                <small class="text-mutted">Em gigabytes</small>
                            </div>
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de banda de tráfego (entrada +
                                    saída)</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.bandwidth_limit"
                                    placeholder=""
                                    id="customerBandwidthLimitInput"
                                />
                                <small class="text-mutted">Em gigabytes</small>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label"
                                    >Limite de tamanho para Logs</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.retention_log_size"
                                    placeholder="Limite tamanho para Database"
                                    id="customerRetentionLogSizeInput"
                                />
                                <small class="text-mutted">Em gigabytes</small>
                            </div>
                            <div class="col-6">
                                <label class="form-label"
                                    >Tempo de retenção para Logs</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="customer.config.retention_log_days"
                                    placeholder=""
                                    id="customerRetentionLogDaysInput"
                                />
                                <small class="text-mutted"
                                    >Em dias corridos</small
                                >
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                            id="customerCancelButton"
                        >
                            Cancelar
                        </button>
                        <button
                            class="btn btn-primary ms-auto"
                            data-bs-dismiss="modal"
                            @click="save()"
                            id="customerSaveButton"
                        >
                            <i class="ti ti-plus"></i>
                            Salvar cliente
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"

export default {
    name: "CustomerForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "create"
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Criar cliente",
            customer: {
                active: false,
                config: {
                    user_limit: null,
                    workspace_limit: null,
                    flow_limit: null,
                    node_limit: null
                }
            },
            workspacesList: [],
            change_password: false
        }
    },
    async mounted() {
        if (this.action === "edit") {
            this.actionTitle = "Editar cliente"
        }

        this.$watch("data", (newData) => {
            if (this.action == "edit") {
                this.customer = structuredClone(newData)
            } else {
                this.customer = {
                    active: false,
                    config: {
                        user_limit: 5,
                        workspace_limit: 1,
                        flow_limit: 15,
                        node_limit: 20,
                        flows_executions: 100000,
                        database_limit: 10,
                        storage_limit: 10,
                        bandwidth_limit: 10,
                        retention_log_size: 1,
                        retention_log_days: 90
                    }
                }
            }
        })
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            this.$emit("save", this.customer)
        }
    }
}
</script>
