<template>
    <div>
        <div class="collapse navbar-collapse" id="navbar-menu">
            <div class="row">
                <div class="col mt-3">
                    <HeaderLogo />
                </div>
            </div>
            <ul class="navbar-nav pt-lg-3">
                <li class="nav-item">
                    <a
                        class="nav-link"
                        href="/customers"
                        id="customersNavbarButton"
                    >
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-building"></i>
                        </span>
                        <span class="nav-link-title">Clientes</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        href="/customers/workspaces"
                        id="workspacesNavbarButton"
                    >
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-box"></i>
                        </span>
                        <span class="nav-link-title">Workspaces</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        href="/customers/users"
                        id="usersCustomersNavbarButton"
                    >
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-users"></i>
                        </span>
                        <span class="nav-link-title">Usuários de clientes</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        href="/flow-templates"
                        id="flowTemplatesNavbarButton"
                    >
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-template"></i>
                        </span>
                        <span class="nav-link-title">Galeria de templates</span>
                    </a>
                </li>
                <li>
                    <hr />
                </li>
                <li class="nav-item" v-if="isAdmin">
                    <a
                        class="nav-link"
                        href="/users"
                        id="usersAdminNavbarButton"
                    >
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-users"></i>
                        </span>
                        <span class="nav-link-title"
                            >Usuários administrativos</span
                        >
                    </a>
                </li>
            </ul>
        </div>
        <WorkspaceSidebarMenu v-if="showWorkspaceSidebarMenu" />
    </div>
</template>

<script>
import HeaderLogo from "./HeaderLogo.vue"
import EventBus from "../services/event-bus"
import { store } from "../store"

export default {
    name: "SidebarMenu",
    components: {
        HeaderLogo
    },
    data() {
        return {
            isAdmin: false
        }
    },
    mounted() {
        if (store.user) {
            this.isAdmin = store.user.is_admin
        }
    }
}
</script>
