<template>
    <div>
        <div class="card" v-if="customerUsersList.length">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-users"></i>
                        Usuários de contas de cliente
                    </h3>
                </div>
                <div class="col-auto">
                    <button
                        @click="newUser()"
                        class="btn btn-primary"
                        id="newUserButton"
                    >
                        <i class="ti ti-plus"></i>
                        Criar usuário
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Sobrenome</th>
                            <th>E-mail</th>
                            <th>Cliente</th>
                            <th>Administrador</th>
                            <th>Status</th>
                            <th>Atualização</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="customerUser in customerUsersList"
                            v-bind:key="customerUser._id"
                        >
                            <td>{{ customerUser.firstname }}</td>
                            <td>{{ customerUser.lastname }}</td>
                            <td>{{ customerUser.email }}</td>
                            <td>{{ customerUser.customer.name }}</td>
                            <td>{{ customerUser.is_admin ? "Sim" : "Não" }}</td>
                            <td>
                                <span
                                    v-if="customerUser.active"
                                    class="badge bg-success-lt"
                                    >Ativo</span
                                >
                                <span v-else class="badge bg-danger-lt"
                                    >Inativo</span
                                >
                            </td>
                            <td>{{ formatDate(customerUser.updated_at) }}</td>
                            <td>
                                <button
                                    @click="loginAsCustomer(customerUser)"
                                    class="btn btn-sm loginAsCustomerButton"
                                >
                                    <i class="ti ti-login"></i>
                                    Login as Customer
                                </button>
                                <span>&nbsp;</span>
                                <button
                                    @click="editCustomerUser(customerUser)"
                                    class="btn btn-sm editAccountButton"
                                >
                                    <i class="ti ti-pencil"></i>
                                    Editar
                                </button>
                                <span>&nbsp;</span>
                                <a
                                    class="btn btn-sm removeAccountButton"
                                    v-bind:href="
                                        '/customers/users/delete/' +
                                        customerUser._id
                                    "
                                    v-if="user.is_admin"
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                Total de
                <strong
                    >{{ customerUsersList.length }} usuários de contas de
                    clientes</strong
                >
                <br />
            </div>
        </div>

        <div class="card" v-if="!customerUsersList.length">
            <div class="card-header">
                <h3 class="card-title">Usuários de contas de clientes</h3>
            </div>
            <div class="card-body">
                <div class="empty">
                    <p class="empty-title">Nenhum usuário criado ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar um novo usuário
                    </p>
                    <div class="empty-action">
                        <button
                            @click="newUser()"
                            class="btn btn-primary"
                            id="newUserButton"
                        >
                            <i class="ti ti-plus"></i>
                            Criar usuário
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <CustomerUserForm
            :action="customerUserFormAction"
            :data="customerUserFormData"
            @save="saveCustomerUser"
            v-if="customerUserFormData"
            :key="randomKey"
        />
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"
import CustomerUserForm from "../components/CustomerUserForm"
import { store } from "../store"

export default {
    name: "CustomerUsersPage",
    components: {
        CustomerUserForm
    },
    data() {
        return {
            customerUserFormAction: "create",
            customerUserFormData: null,
            customerUsersList: [],
            user: {}
        }
    },
    async mounted() {
        this.user = store.user
        await this.loadCustomerUsers()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadCustomerUsers() {
            await Api.customers.users.all().then((response) => {
                this.customerUsersList = response.data
            })
        },
        newUser() {
            this.customerUserFormAction = "create"
            this.customerUserFormData = {}
            this.randomKey = Math.random()
        },
        editCustomerUser(customerUser) {
            this.customerUserFormAction = "edit"
            this.customerUserFormData = customerUser
            this.randomKey = Math.random()
        },
        async loginAsCustomer(customerUser) {
            var tokenResponse = await Api.customers.users.loginAsCustomer(
                customerUser._id
            )
            var url = tokenResponse.data.entrypoint_url
            // open url in a new tab
            window.open(url, "_blank")
        },
        async saveCustomerUser(data) {
            try {
                if (this.customerUserFormAction == "create") {
                    data.workspace = this.workspace_id
                    await Api.customers.users.create(data)
                } else {
                    await Api.customers.users.update(data._id, data)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Usuário salvo com sucesso"
                })

                await this.loadCustomerUsers()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o usuário"
                })
            }
        }
    }
}
</script>
